<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <protex-logo style="width: 150px !important; height: auto" />
      </b-link>
      <b-col lg="12" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="2" md="2" lg="5" class="px-xl-2 mx-auto">
          <!-- #region card -->
          <b-card>
            <b-card-title
              class="mb-1"
              style="font-size: 30px; font-weight: 700"
            >
              Verify Account
            </b-card-title>
            <b-card-text class="mb-2" style="font-size: 14px;
                        color: #7A8B99;
                        font-weight:500;">
              Enter the verification code received via email
            </b-card-text>
            <validation-observer ref="registerForm" #default="{ invalid }">
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="AwsConfirmUser"
              >
              <label
                     style="color: #596D80; font-weight: 500; font-size:14px"
                      for="verify-code"
                      >Code</label
                    >
                <b-form-group  style="border-radius: 6px; box-shadow: 0px 24px 48px 0 rgba(41,58,79,0.15) !important;">
                    
                  
                  <validation-provider
                    #default="{ errors }"
                    name="Code"
                    vid="code"
                    rules="required|digits:6"
                  >
                    <b-form-input
                      id="confirm-user"
                      style="height:58px; font-size:14px;"
                      v-model="verification"
                      type="number"
                      name="confirm-user"
                      :state="errors.length > 0 && verifyPressed? false : null"
                    />
                    <small v-if="verifyPressed" class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  style="color: white !important; font-size: 18px; font-weight:500; height:56px;"
                  :disabled="invalid"
                >
                  Verify Code
                </b-button>
              </b-form>
              <b-link @click="AwsResendCode">
                <p
                  style="
                        margin-top: 10px;
                        font-size: 14px;
                        float: right;
                        color: #7A8B99;
                        font-weight:500;
                        float: right;
                      "
                  id="resend-code"
                >
                  Resend Code?
                </p>
              </b-link>
            </validation-observer>
            <br>
            <center>
            <p style="margin-top: 32px; font-size: 16px; color: #797F93;">
            <span>Already have an account? </span>
            <b-link :to="{ name: 'auth-login' }" style="color: #a2acb5">
              <span id="sign-in" style="color: #00A0F3; text-decoration: none !important">Sign in instead</span>
            </b-link>
          </p>
          </center>
          </b-card>
          <!-- #endregion -->
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */

// #region imports
// General
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ProtexLogo from "@core/layouts/components/Logo.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { Auth } from "aws-amplify";

// Components
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
// #endregion

export default {
  props: ["username"],

  // #region data
  data() {
    return {
      verifyPressed: false,
      status: "",
      verification: "",
      err: "",
      required,
    };
  },
  // #endregion

  // #region components
  components: {
    ProtexLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCard,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  // #endregion

  // #region methods
  methods: {
    async AwsConfirmUser() {
      this.verifyPressed = true
      this.loading = true;
      try {
        await Auth.confirmSignUp(this.username, this.verification);
        this.$toast.success({
          component: ToastificationContent,
          position: "top-center",
          props: {
            title: "Success",
            text: `Account confirmed`,
          },
        });
        this.$router.replace({ name: "auth-login" });
      } catch (error) {
        console.log("error confirming sign up", error);
      }
    },

    async AwsResendCode() {
      try {
        await Auth.resendSignUp(this.username);
        this.$toast.success({
          component: ToastificationContent,
          position: "top-center",
          props: {
            title: "Success",
            text: `code resent successfully check your email`,
          },
        });
      } catch (err) {
        console.log("error resending code: ", err);
        this.$toast.error({
          component: ToastificationContent,
          position: "top-center",
          props: {
            title: "Error",
            text: err,
          },
        });
      }
    },
  },
  // #endregion
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style>
#userback_button_container {
  display: none !important;
}

#crisp-chatbox {
  visibility: hidden !important;
}

#crisp-chatbox > div > a > span.cc-7doi.cc-1ada > span > span.cc-15mo {
  visibility: hidden !important;
}
#crisp-chatbox > div > a > span > span > span{
  visibility: hidden !important;
}

#sign-in:hover {
  color: #00a0f3 !important;
}

#resend-code:hover {
  color: #00a0f3 !important;
}
</style>